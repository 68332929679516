import React, { useState } from 'react';

import Fade from '../../common/Fade';
import { NextArrow, PrevArrow } from '../../common';
import useMediaQuery from '../../../hooks/useMediaQuery.js';

const Banner = ({ banners }) => {
  const isDesktop = useMediaQuery('(min-width: 767px)');
  const [currentBanner, setCurrentBanner] = useState(0);

  const handlePrev = () => {
    if (currentBanner === 0) {
      setCurrentBanner(banners.length - 1);
    } else {
      setCurrentBanner(currentBanner - 1);
    }
  };

  const handleNext = () => {
    if (currentBanner === banners.length - 1) {
      setCurrentBanner(0);
    } else {
      setCurrentBanner(currentBanner + 1);
    }
  };

  return (
    <section className="hero-section">
      {banners.map((banner, index) => (
        <Fade key={banner.id} show={currentBanner === index}>
          <div className="banner">
            <div
              style={{
                backgroundImage: `linear-gradient(270deg, rgba(13, 63, 116, 0) 0%, rgba(4, 24, 46, 1) 100%), url(${
                  isDesktop ? banner.image : banner.mobile_image
                })`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-11 mx-auto banner__container">
                    <div className="banner__info">
                      <h1>{banner.title}</h1>
                      <p>{banner.body}</p>
                      <a
                        href={banner.button_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn secondary-btn"
                      >
                        {banner.button_text}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      ))}
      {banners.length > 1 && (
        <div className="banner-navigation">
          <div className="banner-navigation__indicator">
            {banners.map((banner, index) => (
              <div
                key={banner.id}
                className={currentBanner === index ? 'active' : ''}
                onClick={() => setCurrentBanner(index)}
              />
            ))}
          </div>
          <div className="banner-navigation__buttons">
            <PrevArrow className="btn arrow-btn" onClick={handlePrev} />
            <NextArrow className="btn arrow-btn" onClick={handleNext} />
          </div>
        </div>
      )}
    </section>
  );
};

export default Banner;
