import React, { useState } from 'react';

import ProcessStep from './ProcessStep';
import procesoImg from '../../../images/spots-proceso.png';
import sanitizeHtml from 'sanitize-html';

const Process = ({ innerText, steps }) => {
  const [activeStep, setActiveStep] = useState(1);

  return (
    <section className="process">
      <div className="container">
        <div className="row">
          <div className="col-md-11 mx-auto process__container">
            <div className="process__info">
              <div
                className="services__info"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(innerText),
                }}
              />
              <img src={procesoImg} alt="spots-proceso" />
            </div>

            <div className="process__steps">
              <div className="dotted-line"></div>
              {steps.map((step, index) => {
                return (
                  <ProcessStep
                    key={step.title}
                    number={index + 1}
                    title={step.title}
                    body={step.body}
                    isActive={activeStep === index + 1}
                    onClick={() => setActiveStep(index + 1)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Process;
