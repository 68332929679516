import React from 'react';

import { Link } from 'gatsby';
import useMediaQuery from '../../../hooks/useMediaQuery.js';
import sanitizeHtml from 'sanitize-html';

const Research = ({ innerText, desktopImage, mobileImage, button }) => {
  const isDesktop = useMediaQuery('(min-width: 767px)');

  return (
    <section
      className="research"
      style={{
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundImage: `url(${isDesktop ? desktopImage : mobileImage})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-11 mx-auto research__container">
            <div className="col-md-5 research__info">
              <div
                className="services__info"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(innerText),
                }}
              />
              <Link to="microrate-research" className="btn primary-btn research__link">
                {button}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Research;
