import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import SEO from '../components/seo';
import { Link, graphql } from 'gatsby';
import { Layout } from '../layout/Layout';
import { Banner, Reports, Services, Process, Research, Clients } from '../components/modules/home';
import { Publications, ContactBanner, SectionWithImage } from '../components/common';
import sanitizeHtml from 'sanitize-html';

let isfirstLoad = true;

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        cmsUrl
      }
    }
  }
`;

const HomePage = ({ data, pageContext }) => {
  const cmsURL = data.site.siteMetadata.cmsUrl;

  useEffect(() => {
    // Redirect only on first page load
    if (isfirstLoad) {
      const preferredLanguage = window.navigator.language;
      const language = ['en-US', 'es-ES', 'fr-FR', 'pt-BR'].includes(preferredLanguage) ? preferredLanguage : 'en-US';
      const urlCode = language.split('-')[0];
      const basePath = urlCode === 'en' ? '' : `/${urlCode}`;
      isfirstLoad = false;
      navigate(basePath);
    }
  }, []);

  const { about_image, clients_images, research_image, research_image_mobile } = pageContext.data;

  const {
    about_text,
    about_button,
    reports_title,
    services_text,
    risk_text,
    social_text,
    institutional_text,
    evaluation_text,
    services_button,
    evaluation_process,
    research_text,
    research_button,
    publications_title,
    clients_text,
    contact_banner_text,
    contact_banner_button,
  } = pageContext.translation;

  const banners = pageContext.banners
    // Get banner translation
    .map(banner => {
      return {
        id: banner.id,
        image: banner.image?.id ? `${cmsURL}/assets/${banner.image.id}` : null,
        mobile_image: banner.mobile_image?.id ? `${cmsURL}/assets/${banner.mobile_image.id}` : null,
        ...banner.translations.filter(t => t.languages_id.code === pageContext.languageCode)[0],
      };
    })
    // Only include banners with title
    .filter(banner => banner.title);

  const clientsImages = clients_images.map(({ directus_files_id }) => {
    return {
      id: directus_files_id.id,
      title: directus_files_id.title,
      url: `${cmsURL}/assets/${directus_files_id.id}`,
    };
  });

  return (
    <Layout pageId="home" currentLanguage={pageContext.languageCode}>
      <Banner banners={banners} />
      <SectionWithImage className="about" image={`${cmsURL}/assets/${about_image.id}`}>
        <div
          className="details__content"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(about_text),
          }}
        />
        <Link to="about-us" className="btn primary-btn about__link">
          {about_button}
        </Link>
      </SectionWithImage>
      <Reports title={reports_title} currentLanguage={pageContext.languageCode} />
      <Services
        innerText={services_text}
        riskText={risk_text}
        socialText={social_text}
        institutionalText={institutional_text}
        button={services_button}
      />
      <Process innerText={evaluation_text} steps={evaluation_process} />
      <Research
        innerText={research_text}
        desktopImage={`${cmsURL}/assets/${research_image.id}`}
        mobileImage={`${cmsURL}/assets/${research_image_mobile.id}`}
        button={research_button}
      />
      <Publications currentLanguage={pageContext.languageCode} basePath={pageContext.basePath}>
        <h2>{publications_title}</h2>
      </Publications>
      <Clients title={clients_text} images={clientsImages} />
      <ContactBanner innerText={contact_banner_text} buttonText={contact_banner_button} />
    </Layout>
  );
};

export default HomePage;

export const Head = () => <SEO />;
