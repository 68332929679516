import React, { useEffect, useState } from 'react';

const Fade = ({ show, children }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return shouldRender ? (
    <div
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: show ? 4 : 0,
        animation: `${show ? 'fadeIn' : 'fadeOut'} 0.7s`,
      }}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  ) : null;
};

export default Fade;
