import React from 'react';

import Slider from 'react-slick';
import { Link } from 'gatsby';
import { NextArrow, PrevArrow } from '../../common';
import sanitizeHtml from 'sanitize-html';

const Clients = ({ title, images }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <section className="clients">
      <div className="container">
        <div className="row">
          <div
            className="col-md-11 mx-auto clients__heading"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(title),
            }}
          />

          <div className="col-md-11 mx-auto clients__slider">
            <Slider {...sliderSettings}>
              {images.map(image => (
                <Link key={image.id} to="clients">
                  <img key={image.id} alt={image.title} src={image.url} />
                </Link>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
