import React from 'react';

const ProcessStep = ({ number, title, body, isActive, onClick }) => {
  return (
    <div className={`step ${isActive ? 'active-step' : ''}`}>
      <h3 className="step-header" onClick={onClick}>
        <span className="step-number">{number}</span>
        <span>{title}</span>
      </h3>
      {body
        .split('\n')
        .filter(p => p !== '')
        .map(p => (
          <p key={p} className="step-description">
            {p}
          </p>
        ))}
    </div>
  );
};

export default ProcessStep;
