import React from 'react';

import { Link } from 'gatsby';
import Slider from 'react-slick';

import riesgosImg from '../../../images/spots-riesgos.png';
import socialImg from '../../../images/spots-social.png';
import institucionalImg from '../../../images/spots-institucional.png';
import sanitizeHtml from 'sanitize-html';

const Services = ({ innerText, riskText, institutionalText, socialText, button }) => {
  const settings = {
    dots: true,
    arrows: false,
    variableWidth: true,
    infinite: true,
    slidesToShow: 3,
    appendDots: dots => <ul>{dots}</ul>,
    customPaging: i => (
      <button>
        <span style={{ visibility: 'hidden' }}>{i + 1}</span>
      </button>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <section className="services">
      <div className="container">
        <div className="row">
          <div className="col-md-11 mx-auto services__container">
            <div
              className="services__info"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(innerText),
              }}
            />

            <Slider {...settings}>
              <div className="services__card">
                <img src={riesgosImg} alt="spots-riesgos" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(riskText),
                  }}
                />
                <Link to="credit-rating" className="btn primary-btn">
                  {button}
                </Link>
              </div>

              <div className="services__card">
                <img src={socialImg} alt="spots-social" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(socialText),
                  }}
                />
                <Link to="social-rating" className="btn primary-btn">
                  {button}
                </Link>
              </div>

              <div className="services__card">
                <img src={institucionalImg} alt="spots-institucional" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(institutionalText),
                  }}
                />
                <Link to="institutional-rating" className="btn primary-btn">
                  {button}
                </Link>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
