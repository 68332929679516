import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import sanitizeHtml from 'sanitize-html';
import { viewAllButton } from '../../../translations/buttons';
import { labelTranslations } from '../../../translations/ratings';
import { Stars } from '../../../components/common/Stars.jsx';
import * as moment from 'moment';

const ReportCard = ({ imf, type, country, date, rate, labels }) => {
  return (
    <div className="reports__card">
      <div className="row-1">
        <div className="card-field">
          <span>{labels.imf}</span>
          <span>{imf}</span>
        </div>
        <div className="card-field">
          <span>{labels.country}</span>
          <span>{country}</span>
        </div>
        <div className="card-field">
          <span>{labels.type}</span>
          <span>{type}</span>
        </div>
      </div>

      <div className="row-2">
        <div className="card-field">
          <span>{labels.rate}</span>
          {type === 'Social' ? <Stars number={Number(rate)} /> : <span>{rate}</span>}
        </div>
        <div className="card-field">
          <span>{labels.date}</span>
          <span>{moment(date).format('YYYY-MM')}</span>
        </div>
      </div>
    </div>
  );
};

const Reports = ({ title, currentLanguage }) => {
  const data = useStaticQuery(
    graphql`
      query LastReportQuery {
        directus {
          calificaciones(sort: "-date", limit: 4) {
            id
            imf
            type
            country
            date
            rate
          }
        }
      }
    `
  );

  const reports = data.directus.calificaciones;
  const labelTranslation = labelTranslations[currentLanguage];

  return (
    <section className="reports">
      <div className="container">
        <div className="row">
          <div className="col-md-11 reports__container mx-auto">
            <div className="reports__header">
              <span
                className="title"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(title),
                }}
              />
              <Link to="ratings" className="report__link">
                {viewAllButton[currentLanguage] || 'Ver todo'}
              </Link>
            </div>

            <div className="reports__slider no-scrollbar mx-auto">
              {reports.map(report => {
                return <ReportCard key={report.id} labels={labelTranslation} {...report} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reports;
